<template>
    <div class="container">
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    width:100%;
    height: 320px;
    // margin-top: -60px;
    background: url('../../assets/banner/customer.png') no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        width:288px;
        height:47px;
        font-size:47px;
        font-family:zcool-gdh,zcool;
        font-weight:normal;
        color:rgba(255,255,255,1);
        line-height:47px;
        img{
            height:47px;
            width:760px;
        }
    }
}
</style>