<template>
    <div class="container">
        <div class="content">
            <p class="title">预约产品演示</p>
            <p class="text">想全面了解智君科技的产品及服务，想知道我们能帮您解决哪些问题？
                            留下联系方式，让我们为您安排一场路演吧</p> 
            <div class="form">
                <div :class="['input', showError1 ? 'error error1' : '']">
                    <input type="text" placeholder="您的公司" v-model="company" @input="handleInputCompany" />
                </div>
                <div :class="['input', showError2 ? 'error error2' : '']">
                    <input type="text" placeholder="您的称呼" v-model="name"
                     @input="handleInputName" />
                </div>
                <div :class="['input', showError3 ? 'error error3' : '']">
                    <input type="text" placeholder="您的手机号" v-model="phone" @input="handleInputPhone" />
                </div>
                <button @click="applyToUse">预约演示</button>
            </div>
        </div>

        <el-dialog
            title="预约演示"
            top="30vh"
            width="400px"
            :visible="dialogVisible"
            @close="close">
                <!-- <div slot="title" style="width:100%;height:100%;">预约演示</div> -->
                <div class="flex-row">
                    <div style="font-size:52px"><svg-icon icon-class="success"></svg-icon></div>
                    <div style="margin-left:8px;">
                        <p style="font-size: 16px; color: #333;">预约成功</p>
                        <p style="font-size: 14px; color: #999999;width: 247px;">我们会3个工作日内联系您确定演示日期您也可以拨打以下电话预约优先演示</p>
                        <p style="font-size: 18px; color: #333;" v-if="companyInfo">客服：{{ companyInfo.phone }}</p>
                    </div> 
                </div>
        </el-dialog>
    </div>
</template>

<script>
import { newPost } from '../http'

export default {
    data() {
        return {
            isSubmit: false,
            company: '',
            name: '',
            phone: '',
            showError1: false,
            showError2: false,
            showError3: false,

            dialogVisible: false
        }
    },
    computed: {
        companyInfo: function() {
            return this.$store.state.zongjie.companyInfo
        },
    },
    methods: {
        applyToUse() {
            this.showError1 = false
            this.showError2 = false
            this.showError3 = false
            !this.company && (this.showError1 = true)
            !this.name && (this.showError2 = true)
            !/^[1]([3-9])[0-9]{9}$/.test(this.phone) && (this.showError3 = true)
            if (this.showError1 || this.showError2 || this.showError3) return

            newPost('/api/system/sysTrial/apply', {
                company: this.company,
                name: this.name,
                phone: this.phone
            }).then((res) => {
                console.log(res)
                this.isSubmit = true
                this.dialogVisible = true
            })
        },
        handleInputCompany() {
            this.showError1 = this.company == '' ? true : false
        },
        handleInputName() {
            this.showError2 = this.name == '' ? true : false
        },
        handleInputPhone() {
            this.showError3 = !/^[1]([3-9])[0-9]{9}$/.test(this.phone) ? true : false
        },
        hideMask() {
            this.isSubmit = false
            this.company = ''
            this.name = ''
            this.phone = ''
            this.showError1 = false
            this.showError2 = false
            this.showError3 = false
        },
        close() {
           this.dialogVisible = false
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    height: 270px;
    background: #FAFAFA;
}
.title{
    font-family: 'PingFang SC';
    margin: 51px auto 12px;
    text-align: center;
    font-size: 36px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:#333;
    line-height:50px;
}
.container .text{
    margin: 12px auto 24px;
    width: 434px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 20px;
}
.form{
    display: flex;
    justify-content: space-between;
    .input{
        width: 288px;
    }
    input, button{
        width: 288px;
        height: 60px;
        line-height: 60px;
        border-radius: 2px;
        outline: none;
    }
    input{
        box-sizing: border-box;
        border: 1px solid #666666;
        padding: 0 18px;
        font-size: 16px;
        // background: transparent;
        background: #fff;
    }
    button{
        background: #5F80A9;
        border: 2px solid #5F80A9;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 56px;
        cursor: pointer;
    }
}

.apply-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .apply-content{
        width: 600px;
        height: 300px;
        background: #FFFFFF;
        border: 1px solid #ddd;
        position: relative;
    }
    .font48{
        font-size: 48px;
        font-family: 'PingFang SC';
        font-weight: 100;
        margin-top: 80px;
        img{
            margin-bottom: -4px;
            margin-right: 2px;
        }
    }
    .font16{
        font-size: 16px;
        margin-top: 40px;
    }
    .close-icon{
        position: absolute;
        z-index: 99;
        top: 0;
        right: 6px;
        font-size: 50px;
        line-height: 50px;
        cursor: pointer;
        color: #666666;
    }
}
.error{
    font-size: 14px;
    color: red;
    text-align: left;
    &::after{
        padding-left: 20px;
    }
}

.flex-row{
    display:flex;
    justify-content: flex-start;
    align-items:flex-start;
    text-align: left;
    color: #666;
    line-height: 28px;
    padding: 20px 16px 48px;

}
/deep/ .el-dialog__header{
    background:#4E7CEE;
    padding: 23px;
}
/deep/ .el-dialog__title{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}
/deep/ .el-dialog__close.el-icon.el-icon-close{
    display: none;
}

.error1::after{
    content: '请填写您的公司名称';
}
.error2::after{
    content: '请填写您的称呼';
}
.error3::after{
    content: '请填写11位手机号';
}
</style>